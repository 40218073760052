import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import {Box, Chip, ListItemIcon, TextField} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {InfoOutlined} from "@mui/icons-material";
import ConfirmCheckinDialog from "./ConfirmCheckinDialog";
import {customTheme} from "../../theme/customTheme";
import CustomDialogTitle from "../CustomDialogTitle";

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchUserDialog({open, handleClose, setUser, users}) {
    document.body.style.backgroundColor = customTheme.palette.secondary.main;

    const [filter, setFilter] = useState('');
    const [infoOpen, setInfoOpen] = useState(false);

    const showInfo = (e, rowData) => {
        setInfoOpen(rowData)
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{sx: {borderRadius: '1.1rem 1.1rem 0 0'}}}
            variant={'checkin'}
        >
            {<CustomDialogTitle title={'Cerca partecipante'} handleCloseDialog={handleClose}/>}
            {/*<Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon/>
                </IconButton>
                <Button autoFocus color="inherit" onClick={handleClose}>
                    chiudi
                </Button>
            </Toolbar>*/}
            <Box sx={{mx: 2}}>
                <TextField id="outlined-basic" label="Filtra" variant="outlined" sx={{width: '100%'}} onChange={(e) => setFilter(e.target.value.trim())} />
            </Box>
            <ConfirmCheckinDialog open={!!infoOpen} handleClose={() => setInfoOpen(null)} user={infoOpen ? infoOpen : null} info/>
            {filter.length < 2 && <Typography sx={{p: 2}} variant={"subtitle2"}>Inserisci almeno due lettere</Typography>}
            <List sx={{pt: 2}} dense>
                {filter.length >= 2 && users
                    .filter((user) =>
                        (user?.nome?.trim().toLowerCase() + ' ' + user?.cognome?.trim().toLowerCase())?.includes(filter.toLowerCase())
                        || (user?.cognome?.trim().toLowerCase() + ' ' + user?.nome?.trim().toLowerCase())?.includes(filter.toLowerCase())
                        || user?.email?.toLowerCase()?.includes(filter.toLowerCase()))
                    .sort((a, b) => a.email?.localeCompare(b.email))
                    .map((val) =>
                    <ListItem
                        key={val.id}
                        dense
                        secondaryAction={
                            (val.checkinNr16 === false || !val.checkinNr16) ?
                                <Button variant={"outlined"}
                                        size={'small'}
                                        endIcon={<CheckCircleIcon sx={{ color: 'darkseagreen', fontSize: '2.5rem' }} />}
                                        onClick={(e) => {
                                            setUser(val)
                                            handleClose()
                                        }}>Check-in</Button>
                                : val.checkedInAt ?
                                        <Chip label={`Check-in ${val.checkedInAt ? new Date((val.checkedInAt?._seconds * 1000)).toLocaleTimeString('it-IT') : ''}`}/>
                                        : val.email
                        }
                    >
                        <ListItemIcon>
                            <IconButton onClick={(e) => showInfo(e, val)} >
                                <InfoOutlined fontSize={'small'} color={'primary'}/>
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText
                            primary={val.nome +  ' ' + val.cognome}
                            secondary={val.email}
                        />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
}
