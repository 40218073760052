import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../config";

const usersUrl = ({archived = false, all = false}) => {
    if(!!all && all === true)
        return `${API_URL}users?all=true`
    return !!archived ? `${API_URL}users${archived ? '?archived=false':''}`
        : `${API_URL}users`
}

const userDataUrl = (id) => {
    return `${API_URL}users/${id}`
}

const archiveUserUrl = (id, archive) => {
    return archive ? `${userDataUrl(id)}/archive` : `${userDataUrl(id)}/unarchive`
}

const resendEmailToUserUrl = (id) => {
    return `${userDataUrl(id)}/resend-invitation`
}

const sendQrToUserUrl = (id) => {
    return `${userDataUrl(id)}/send-qr`
}

const resendAgendaToUserUrl = (id) => {
    return `${userDataUrl(id)}/send-agenda`
}

const UserService = {
    usersUrl,
    userDataUrl,
    archiveUserUrl,
    resendEmailToUserUrl,
    sendQrToUserUrl,
    resendAgendaToUserUrl
};

export default UserService;
