import {useParams} from "react-router-dom";
import React, {useState} from "react";
import axios from "axios";
import UserService from "../../services/user.service";
import CustomTooltip from "../../components/CustomTooltip";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {MarkEmailUnread, Send} from "@mui/icons-material";

const ResendEmail = ({ archived, setMessage }) => {
    const {id} = useParams()
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleToggleConfirmDialog = () => {
        setOpenConfirmDialog(!openConfirmDialog)
    }

    function handleSendEmail() {
        axios.get(UserService.resendAgendaToUserUrl(id),
            {
                method: "GET"
            })
            .then(() => {
                setMessage({show: true, text: "Invio effettuato", severity: "success"})
                handleToggleConfirmDialog()
            })
            .catch((err) => {
                setMessage({
                    show: true,
                    text: "Invio non andato a buon fine",
                    severity: "error"
                })
            })
    }

    return (<>
            <CustomTooltip title={'Invia e-mail AGENDA'}
                           children={
                               <Button variant={'text'}
                                       color={'accent'}
                                       disabled={archived}
                                   //size={'small'}
                                       sx={{mr: 2}}
                                       startIcon={<MarkEmailUnread/>}
                                       onClick={handleToggleConfirmDialog}>
                                   Invia AGENDA
                               </Button>
                           }
            />
            <Dialog open={openConfirmDialog} onClose={handleToggleConfirmDialog}>
                <DialogContent>
                    <DialogContentText>
                        Sei sicuro di voler inviare l'email all'utente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleConfirmDialog}>Annulla</Button>
                    <Button onClick={handleSendEmail} variant={'submit'}
                            startIcon={<Send/>}>
                        {'Invia AGENDA'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ResendEmail