export const API_URL = "https://atlantia-api-23-i7zinnljra-ey.a.run.app/";
//export const API_URL = "http://localhost:8080/";
//export const API_URL = "https://dev-api-davide.plesh.co/";

export const appName = process.env.REACT_APP_APP_NAME || 'Dashboard'

export const events = {
    NJ15: {
        id: 'nj15',
        label: 'New Journey - 15 Marzo 2023'
    },
    NR16: {
        id: 'nr16',
        label: 'New Rhythm - 16 Marzo 2023'
    },
    BOTH: {
        id: 'Entrambi',
        label: 'Entrambi i giorni'
    }
}