import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Fab,
    FormControlLabel,
    FormGroup,
    Grow,
    Paper,
    Stack,
    styled,
    TextField,
    Typography
} from "@mui/material";
import CustomPageHeader from "../../components/CustomPageHeader";
import {Add, Cancel, Help, Save} from "@mui/icons-material";
import AuthService from "../../services/auth.service";
import CustomSwitch from "../../components/CustomSwitch";
import {GlobalContext} from "../../state/global";
import SettingsService, {forms} from "../../services/settings.service";
import {setGlobalSettings} from "../../state/global/globalActions";
import useAxios from "axios-hooks";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomLoader from "../../components/CustomLoader";
import {filter, find, orderBy, remove, union} from "lodash";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../../components/CustomTooltip";
import CustomFormCard from "../../components/CustomFormCard";
import SetImage from "../../components/SetImage";
import axios from "axios";
import SettingsDialog, {SettingsDialogTypes} from "./SettingsDialog";
import ColumnsService, {commonFields} from "../../services/columns.service";
import {fieldTypes} from "../../services/utils";
import MenuItem from "@mui/material/MenuItem";
import {lighten} from "@mui/system/colorManipulator";
import {customTheme} from "../../theme/customTheme";

function CheckinFieldsToShow({settings, setSettings}) {
    const maxFields = 4

    const [globalState, dispatch] = useContext(GlobalContext)

    const [state, setState] = useState({
        selectedForm: find(globalState.dataStructures, ['main', true]),
        checkInFields: []
    })

    useEffect(() => {
        if (state.checkInFields.length > 0) {
            setSettings({
                ...settings,
                checkInFields: union(settings.checkInFields, state.checkInFields.map((field) => (field.id)))
            })
        }
    }, [state.checkInFields])

    const optionsFields = useMemo(() => {
        if (!globalState.dataStructures)
            return []

        const mainForm = !!state.selectedForm ?
            find(globalState.dataStructures, ['id', state.selectedForm?.id])
            : find(globalState.dataStructures, ['main', true])

        let res = filter(
            ColumnsService.getFieldsFromForm(mainForm),
            function (o) {
                return o.type !== fieldTypes.CUSTOM.type
                    && o.type !== fieldTypes.ACCESS_TYPE.type
                    && o.type !== fieldTypes.URL.type
            }
        )
        res = filter(res, function (o) {
            return !find(commonFields, ['id', o.id])
        })

        return filter(res, function (o) {
            return !find(settings.checkInFields, ['id', o.id])//.includes(o.id)
        })
    }, [state.selectedForm, globalState.dataStructures, settings]);

    const GroupHeader = styled('div')(({theme}) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 8px',
        color: theme.palette.accent.main,
        fontSize: '0.8rem',
        backgroundColor: lighten(theme.palette.accent.main, 0.9)
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });

    function handleChangeSelectedForm(event) {
        setState({...state, selectedForm: event.target.value})
    }

    function handleDeleteCheckInField(field) {
        remove(settings.checkInFields, function (o) {
            return o.id === field
        })

        setSettings({
            ...settings,
            checkInFields: settings.checkInFields
        })
    }

    return (
        <Stack py={1} px={2} spacing={3}>
            <Stack p={2} direction={'row'} alignItems={'baseline'} spacing={1} component={Paper}
                   sx={{background: lighten(customTheme.palette.primary.main, 0.9)}}
            >
                <CustomTooltip
                    title={"Verranno mostrati insieme ai dati obbligatori Nome, Cognome e E-mail alla scansione del QR code"}
                    children={<IconButton size={'small'}>
                        <Help color={'disabled'}
                              fontSize={'small'}/>
                    </IconButton>}
                />
                <Typography variant={'body1'} fontWeight={'bold'}>Dati opzionali mostrati al check-in: </Typography>
                <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
                    {
                        settings.checkInFields?.map((field, i) => (
                            <Chip
                                key={i}
                                size={'small'}
                                //variant={'accent-outlined'}
                                sx={{marginBottom: 0.5, marginRight: 0.5}}
                                color={"primary"}
                                label={field.label}
                                deleteIcon={<Cancel/>}
                                onDelete={() => handleDeleteCheckInField(field.id)}
                            />
                        ))
                    }
                </Stack>
            </Stack>
            <Stack direction={"row"} spacing={2}>
                <TextField variant={'outlined'} size={'small'}
                           select
                           sx={{width: '60%'}}
                           id={'form'}
                           name={'form'}
                           label={'Form'}
                           defaultValue={find(globalState.dataStructures, ['main', true])}
                           value={state.selectedForm}
                           onChange={handleChangeSelectedForm}
                >
                    {
                        filter(Object.values(globalState.dataStructures), function (o) {
                            return o.id !== forms.CHECKIN.id
                        }).map((option) => (
                            <MenuItem key={option.label} value={option} children={
                                <Stack direction={'row'} spacing={1}>
                                    <div>{option.label}</div>
                                </Stack>
                            }/>
                        ))}
                </TextField>
                <Stack width={'100%'} direction={'row'} alignItems={'flex-start'}>
                    <Autocomplete
                        fullWidth
                        size={'small'}
                        disableCloseOnSelect
                        groupBy={(option) => option.section}
                        onChange={(event, newValue) => {
                            if (newValue && settings.checkInFields.length < maxFields)
                                setSettings({
                                    ...settings,
                                    checkInFields: settings.checkInFields.concat([newValue])
                                })
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Aggiungi campi da mostrare in fase di check-in"
                                       helperText={`Puoi aggiungerne fino a ${maxFields}`}
                            />}
                        options={optionsFields}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}

const Settings = () => {
    const [globalState, dispatch] = useContext(GlobalContext)

    const [{data, loading: loadingSettings, error: errorSettings}, saveSettings] = useAxios(
        {method: "POST"}, {manual: true}
    )

    const [settings, setSettings] = useState({
        eventName: globalState?.settings?.eventName || '',
        logoUrl: globalState?.settings?.logoUrl || null,
        checkin: false,
        checkInFields: globalState?.settings?.checkInFields || []
    })

    useEffect(() => {
        if (globalState.settings) {
            setSettings({
                eventName: globalState.settings.eventName,
                checkin: globalState.settings.checkin,
                logoUrl: globalState.settings.logoUrl || null,
                checkInFields: globalState?.settings?.checkInFields || []
            })
        }
    }, [globalState.settings])

    const handleEventName = (event) => {
        setSettings({...settings, eventName: event.target.value});
    };

    const handleCheckInChange = (event) => {
        setSettings({...settings, checkin: event.target.checked});
    };

    const handleSaveSettings = () => {
        saveSettings({
            url: SettingsService.settingsUrl(),
            data: {...settings}
        })
            .then((res) => {
                dispatch(setGlobalSettings({
                    ...globalState.settings,
                    ...settings
                }))
            })
            .catch((err) => console.log("err:", err))
    }

    const handleUpdateImage = async (file) => {
        //console.log("file:", file)
        //setSettings({...settings, logoUrl: file});
        if (file !== null) {
            const formData = new FormData();
            formData.append('file', file);
            axios({
                url: `${SettingsService.settingsUrl()}/logo`,
                method: 'POST',
                data: formData,
            })
                .then((res) => {
                    if (res)
                        dispatch(setGlobalSettings({
                            ...globalState.settings,
                            logoUrl: res.data.logoUrl
                        }))
                })
                .catch((err) => console.log("handleUpdateImage --> err:", err))
        }
    }

    const [dialogState, setDialogState] = useState({
        open: false,
        data: null,
        type: null
    })

    const handleOpenDialog = () => {
        setDialogState({open: true, type: SettingsDialogTypes.NEW_DATA_STRUCTURES, data: null})
    }

    const handleCloseDialog = () => {

    }

    return (
        <div>
            {globalState.settings ?
                <div>
                    <CustomBackdrop open={loadingSettings} children={<CustomLoader/>}/>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <CustomPageHeader title={"Impostazioni"} subtitle={'Descrizione impostazioni progetto'}/>
                        <Button variant={'outlined'} color={'accent'}
                                startIcon={<Save/>}
                                onClick={handleSaveSettings}>
                            SALVA
                        </Button>
                    </Box>
                    <Box p={3} my={1} display={'flex'} alignItems={'start'} flexDirection={'column'} component={Paper}
                         variant={'outlined'}>
                        <Typography variant={'h6'}>Generali</Typography>
                        <Box display={'flex'} width={'100%'} alignItems={'center'} flexWrap={'wrap'}>
                            <Box flex={1} mr={2} maxWidth={'350px'}>
                                <SetImage caption={'Immagine Evento / Logo azienda'} tooltip
                                          logoUrl={settings.logoUrl}
                                          handleUpdateImage={handleUpdateImage}
                                />
                            </Box>
                            <Box flex={1} ml={2} maxWidth={'400px'}>
                                <TextField size={'small'} variant={'standard'}
                                           sx={{mt: 1}}
                                           label={'Nome dell\'evento'}
                                           fullWidth
                                           value={settings.eventName}
                                           onChange={handleEventName}>
                                </TextField>
                            </Box>
                        </Box>
                    </Box>
                    <Box p={3} my={1} display={'flex'} alignItems={'start'} flexDirection={'column'}
                         component={Paper} variant={'outlined'}>
                        <Typography variant={'h6'}>I form dell'evento</Typography>
                        {
                            <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                                {
                                    orderBy(Object.values(globalState?.dataStructures || []), 'position')
                                        .map((dataStructure, i) => {
                                            if (dataStructure.id !== forms.CHECKIN.id)
                                                return <Box key={i} mt={1} mr={2}>
                                                    <CustomFormCard id={dataStructure.id} label={dataStructure.label}
                                                                    description={dataStructure.description}/>
                                                </Box>
                                        })
                                }
                                {AuthService.getCurrentUserAdmin() &&
                                    <CustomTooltip title={'Aggiungi nuovo form'} children={
                                        <IconButton color={'accent'} onClick={handleOpenDialog}>
                                            <Add/>
                                        </IconButton>}/>}
                                <SettingsDialog dialogState={dialogState} setDialogState={setDialogState}/>
                            </Box>
                        }
                    </Box>
                    {AuthService.getCurrentUserAdmin() ?
                        <Stack p={3} my={1} component={Paper} variant={'outlined'}>
                            <Typography variant={'h6'}>Impostazioni di check in</Typography>
                            <Stack alignItems={'flex-start'} justifyContent={'center'}>
                                <Stack direction={'row'}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<CustomSwitch
                                                checked={settings.checkin}
                                                onChange={handleCheckInChange}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                            label="L'evento prevede check-in"
                                            labelPlacement="start"/>
                                    </FormGroup>
                                </Stack>
                                {settings?.checkin &&
                                    <Grow in={settings?.checkin}>
                                        <Box width={'100%'}>
                                            <CheckinFieldsToShow settings={settings} setSettings={setSettings}/>
                                        </Box>
                                    </Grow>}
                            </Stack>
                        </Stack>
                        : null}
                    <Fab onClick={handleSaveSettings}
                         color={'primary'}
                         size={"medium"}
                         variant={'action'}>
                        <Save/>
                    </Fab>
                </div> : <Typography>Errore nel caricamento dei dati</Typography>}
            {errorSettings && <Typography>Errore nel caricamento dei dati</Typography>}
        </div>
    )
}

export default Settings