import React, {useContext, useEffect} from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import AuthService from "../services/auth.service";
import Header from "./appbar/Header";
import {APP_BAR_HEIGHT} from "../theme/customTheme";
import {Box, Typography} from "@mui/material";
import CustomLoader from "./CustomLoader";
import {GlobalContext} from "../state/global";
import useAxios from "axios-hooks";
import SettingsService from "../services/settings.service";
import DataStructuresService from "../services/dataStructures.service";
import {setDataStructures, setGlobalSettings} from "../state/global/globalActions";

const ProtectedRoute = ({children, user}) => {
    const [globalState, dispatch] = useContext(GlobalContext)

    const [{data: dataSettings, loading: loadingSettings, error: errorSettings}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    const [{data: dataStructures, loading: loadingDataStructures, error: errorDataStructures}] = useAxios(
        DataStructuresService.dataStructuresUrl(), {method: "GET", useCache: false, manual: false}
    )

    useEffect(() => {
        if (dataSettings) {
            dispatch(setGlobalSettings(dataSettings.settings))
        }
    }, [dataSettings])

    useEffect(() => {
        if (dataStructures) {
            dispatch(setDataStructures(dataStructures.data))
        }
    }, [dataStructures])

    if (!user) {
        return <Navigate to="/login" replace/>;
    } else {
        if (!AuthService.isLogged(user)) {
            return <Navigate to="/login" replace/>;
        }
    }

    return <div className="App" style={{marginTop: APP_BAR_HEIGHT}}>
        {//globalState.settings && globalState.dataStructures &&
            AuthService.isLogged(localStorage.user) ?
                <Header children={
                    <Box m={2} px={3}>
                        {children ?
                            children : <Outlet/>}
                    </Box>}/>
            : <Typography>Finisci di fare il setup del progetto</Typography>
        }
        {(loadingSettings || loadingDataStructures) && <CustomLoader/>}
        {(errorSettings || errorDataStructures) && <Typography>Ops... qualcosa è andato storto</Typography>}
    </div>
};

export default ProtectedRoute
