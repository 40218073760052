import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemIcon, TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useContext, useEffect, useState} from "react";
import CustomLoader from "../CustomLoader";
import ListItemText from "@mui/material/ListItemText";
import {Info} from "@mui/icons-material";
import {GlobalContext} from "../../state/global";
import DataStructuresService from "../../services/dataStructures.service";
import {setDataStructures, setGlobalSettings} from "../../state/global/globalActions";
import useAxios from "axios-hooks";
import SettingsService from "../../services/settings.service";

export const OptionalCheckinUserData = ({userData, numBadge, handleChangeNumBadge, info, dividerColor = 'white',}) => {
    const [globalState, dispatch] = useContext(GlobalContext)

    const [{data: dataSettings, loading: loadingSettings, error: errorSettings}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    const [{data: dataStructures, loading: loadingDataStructures, error: errorDataStructures}] = useAxios(
        DataStructuresService.dataStructuresUrl(), {method: "GET", useCache: false, manual: false}
    )

    useEffect(() => {
        if (dataSettings) {
            dispatch(setGlobalSettings(dataSettings.settings))
        }
    }, [dataSettings])

    useEffect(() => {
        if (dataStructures) {
            dispatch(setDataStructures(dataStructures.data))
        }
    }, [dataStructures])

    function handleChange(e) {
        handleChangeNumBadge(e.target.value)
    }

    return (
        <Box>
            {
                globalState.settings && globalState.dataStructures &&
                <List>
                    {
                        globalState.settings.checkInFields?.map((field) => (
                            <>
                                <Divider color={dividerColor}/>
                                <ListItem sx={{px:0}}>
                                    {/*<ListItemIcon>
                                    {(userData && userData[field.id]) ?
                                        userData ? [field.id] === 'si' ? <Restaurant/> : <NoMeals/>}
                                </ListItemIcon>*/}
                                    <ListItemIcon>
                                        {<Chip variant={'accent-outlined'}
                                               size={'small'}
                                               sx={{marginRight: 1}}
                                               label={field.label}
                                        />}
                                    </ListItemIcon>
                                    {
                                        (field.id !== 'tipoBadge' || info) ? <ListItemText
                                            primary={((userData && userData[field.id]) ? userData[field.id].toString()
                                                : field.id === 'tipoBadge' ? numBadge : '-')}
                                        />
                                        :
                                                <TextField size={'small'}
                                                       id={'tipoBadge'}
                                                       name={'tipoBadge'}
                                                       value={numBadge}
                                                       onChange={handleChange}
                                            />
                                    }
                                </ListItem>
                            </>

                        ))
                    }
                    <Divider color={dividerColor}/>
                </List>
            }
            {(loadingSettings || loadingDataStructures) && <CustomLoader/>}
            {(errorSettings || errorDataStructures) && <Typography>Ops... qualcosa è andato storto</Typography>}
        </Box>
    );
}

const ConfirmCheckinDialog = ({open, handleClose, handleConfirmCheckIn, user, info = false, error,
                                  numBadge, handleChangeNumBadge}) => {
    return (
        <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Box display={'flex'} alignItems={'center'}>
                    <Info color={'primary'} sx={{mr: 2}}/>
                    {info ? "Informazioni" : "Conferma il Check-in"}
                </Box>
            </DialogTitle>
            <DialogContent sx={{px:1}}>
                <DialogContent id="alert-dialog-description">
                    {!user && <CustomLoader/>}
                    {error && <Alert severity={"error"} sx={{m: 0, mb: 2, boxShadow: '0px 1px 18px 0px rgba(48,66,75,0.45)'}}>
                        <AlertTitle>QR Code non valido.</AlertTitle>
                        <Typography variant={'subtitle2'}>{error.response.data.error}</Typography>
                    </Alert>}
                    {user ?
                        <Box>
                            <Typography gutterBottom variant={"h5"}>{user?.nome} {user?.cognome}</Typography>
                            <OptionalCheckinUserData userData={user}
                                                     info={info}
                                                     numBadge={numBadge}
                                                     handleChangeNumBadge={handleChangeNumBadge}
                            />
                        </Box> : null}
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Annulla
                </Button>
                {!info && <Button onClick={(e) => handleConfirmCheckIn(e, user.id)} autoFocus variant={'submit'}>
                    Conferma il Check-in
                </Button>}
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmCheckinDialog;
