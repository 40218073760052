import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Box, Divider, ListItem, ListItemIcon, Typography} from "@mui/material";
import {QrReader} from 'react-qr-reader';
import {API_URL} from "../config";
import axios from "axios";
import SearchUserDialog from "../components/checkin/SearchUserDialog";
import ConfirmCheckinDialog, {OptionalCheckinUserData} from "../components/checkin/ConfirmCheckinDialog";
import CheckinAppBar from "../components/checkin/CheckinAppBar";
import {Headset, HeadsetOff, Info, InfoOutlined, NoMeals, Restaurant} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import {APP_BAR_HEIGHT, customTheme} from "../theme/customTheme";

const CheckIn = () => {
    document.body.style.backgroundColor = customTheme.palette.secondary.main;

    const [data, setData] = useState('');
    const [user, setUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [userDataDialog, setUserDataDialog] = React.useState(false);

    const [alertSucces, setAlertSuccess] = useState(false);
    const [error, setError] = useState(false);

    const constraints = {
        facingMode: "environment"
    };

    const getUser = async (id) => {
        const _user = await axios.get(API_URL + "participantsFisici/" + id);
        setUser(_user.data)
    };

    const checkinUser = async (id, numBadge) => {
        setError(false);
        //setUser(null)
        try {
            await axios.post(API_URL + "participantsFisici/" + id + "/checkin"+"?nr16="+true, {
                tipoBadge: numBadge
            });
            setAlertSuccess(true)
            return true;
        } catch (e) {
            if (e.response.status === 409) {
                console.log(e);
                setError(e);
                return false;
            } else {
                setError(e);
                return false;
            }
        }
    };

    useEffect(() => {
        setNumBadge(null)
        fetchData().then(() => {
            if(user) {
                setUserDataDialog(true);
            }
        })
    }, [user])

    useEffect(() => {
        if (data !== '') {
            setAlertSuccess(false)
            setError(false)
            setUser(null)
            setNumBadge('')
            getUser(data).then(res => {
                //checkinUser(data).then()
                setUserDataDialog(true);
            });
        }
    }, [data])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const [numBadge, setNumBadge] = useState('')

    const handleChangeNumBadge = (val) => {
        setNumBadge(val);
    }

    const handleConfirmCheckin = (e, res) => {
        const id = res?.hash || res || user.id
        checkinUser(id, numBadge)
            .then(resCheckin => {
                if (resCheckin) {
                    setOpen(false)
                    setUserDataDialog(false);
                    setNumBadge(numBadge)
                    fetchData()
                }
            })
            .catch((e) => {
                setError(e)
            })
        ;
        //setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function fetchData() {
        const _userList = await axios.get(API_URL + "participantsFisici");
        setUserList(_userList.data)
    }

    function hanldleCloseAlert() {
        setError(false)
        setUser(null)
        setAlertSuccess(false)
    }

    return (
        <main>
            <CheckinAppBar handleDialogOpen={handleClickOpen}/>
            {user && alertSucces &&
                <Box position={'fixed'} top={'50px'} left={0} right={0} zIndex={1}>
                    <Alert severity="success" sx={{m: 2, boxShadow: '0px 1px 18px 0px rgba(48,66,75,0.45)'}}
                           onClose={hanldleCloseAlert}
                    >
                        <AlertTitle>
                            {user?.nome} {user?.cognome}
                        </AlertTitle>
                        <Typography variant={'subtitle2'}>{user?.email}</Typography>
                        <Typography variant={'subtitle2'} fontWeight={'bold'}>CHECK-IN AVVENUTO CON SUCCESSO</Typography>
                    </Alert>
                </Box>}
            {error &&
                <Box position={'fixed'} top={'50px'} left={0} right={0} zIndex={1}>
                    <Alert severity={"error"} sx={{m: 2, boxShadow: '0px 1px 18px 0px rgba(48,66,75,0.45)'}}
                           onClose={hanldleCloseAlert}
                    >
                        <AlertTitle>QR Code non valido.</AlertTitle>
                        <Typography variant={'subtitle2'}>{error.response.data.error}</Typography>
                    </Alert>
                </Box>}
            <Box width={'100%'} mt={`${APP_BAR_HEIGHT}px`}>
                <QrReader
                    onResult={(result, error) => {
                        if (!!result) {
                            //console.log("result:",result)
                            setData(result?.text);
                        }

                        if (!!error) {
                            //console.log(error);
                        }
                    }}
                    videoContainerStyle={{width: '100%'}}
                    constraints={constraints}
                />
            </Box>
            {user &&
                <Box px={4} pb={2} sx={{color: 'white'}}>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <Info fontSize={'large'} color={'error'}/>
                        </ListItemIcon>
                        <ListItemText primary={`Informazioni: ${user?.nome} ${user?.cognome}`}/>
                    </ListItem>
                    <OptionalCheckinUserData userData={user} dividerColor={'white'} info numBadge={numBadge}/>
                </Box>
            }
            <SearchUserDialog open={open} handleClose={handleClose} setUser={setUser} users={userList}/>
            <ConfirmCheckinDialog open={userDataDialog}
                                  handleConfirmCheckIn={handleConfirmCheckin}
                                  numBadge={numBadge} handleChangeNumBadge={handleChangeNumBadge}
                                  handleClose={() => setUserDataDialog(false)}
                                  error={error}
                                  user={user ? user : null}/>
        </main>
    )
}

export default CheckIn
