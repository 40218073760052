import * as React from 'react';
import {useMemo, useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import _, {startCase} from 'lodash'
import {Box, Chip} from "@mui/material";
import {
    AirlineStops,
    Drafts,
    ExpandLess,
    ExpandMore,
    MarkEmailUnread,
    PersonAdd,
    PersonRemove,
    TouchApp
} from "@mui/icons-material";

const actions = {
    'sent': {
        icon: <MarkEmailUnread/>,
        subtitle: "campaign_title"
    },
    'open': {
        icon: <Drafts/>,
        variant: 'outlined',
        subtitle: "campaign_title"
    },
    'click': {
        icon: <TouchApp/>,
        subtitle: "link_clicked"
    },
    'unsub': {
        icon: <PersonRemove/>,
        variant: 'outlined'
    },
    'generic_signup': {
        icon: <PersonAdd/>,
        subtitle: "signup_category"
    },
    'bounce': {
        icon: <AirlineStops/>,
        subtitle: "bounce_type",
    },
}

const maxActivity = 4
export default function CustomizedTimeline({mailchimpActivity}) {
    const [showAllActivity, setShowAllActivity] = useState(false)

    const act = useMemo(() => {
        if(showAllActivity)
            return mailchimpActivity
        else return mailchimpActivity?.slice(0,maxActivity) || []
    }, [showAllActivity])

    if(_.isEmpty(mailchimpActivity))
        return <Typography align={"center"} variant={"body2"}>Nessuna attività</Typography>
    return (<Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Timeline>
            {
                act.map((item, i) =>
                    item && Object.keys(item).length > 0 &&
                        <TimelineItem key={i}>
                            <TimelineOppositeContent
                                sx={{m: 'auto 0'}}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                {new Date(item.created_at_timestamp).toLocaleString()}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector/>
                                <TimelineDot color="primary" variant={actions[item.activity_type]?.variant}>
                                    {actions[item.activity_type]?.icon}
                                </TimelineDot>
                                <TimelineConnector/>
                            </TimelineSeparator>
                            <TimelineContent sx={{py: '12px', px: 2}}>
                                <Typography color={'primary'} variant="body1" component="span">
                                    {startCase(item.activity_type)}
                                </Typography>
                                {/*<Typography variant={"subtitle2"} fontWeight={'lighter'}>
                                    {_.startCase(item.activity_type)} {item.activity_type === "click" ?
                                    item.url.startsWith('http') ?
                                        <Link href={item.url} target={"_blank"}>{item.url}</Link>
                                        : "Custom link"
                                    : ""}
                                </Typography>*/}
                                <Typography variant={"subtitle2"} fontWeight={'lighter'}>
                                    {actions[item.activity_type]?.subtitle ?
                                        item[actions[item.activity_type]?.subtitle] : ""
                                    }
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                )
            }
        </Timeline>
        {mailchimpActivity.length - maxActivity > 0 && <Chip label={showAllActivity ? "Mostra più recente" : `Mostra tutto (${mailchimpActivity.length} azioni)`}
               deleteIcon={showAllActivity ? <ExpandLess/> : <ExpandMore/>}
               onDelete={() => setShowAllActivity(!showAllActivity)}
               onClick={() => setShowAllActivity(!showAllActivity)}
               sx={{margin: 'auto'}}
        />}
    </Box>);
}