import React, {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {alpha, Box, Button, Chip, Fab, Grow, LinearProgress, Snackbar, Stack, Switch, Tab, Tabs} from "@mui/material";
import {DataGrid, itIT} from '@mui/x-data-grid'
import UserService from "../../services/user.service";
import {Alert} from "@mui/lab";
import CustomGridToolbar from "../../components/data-grid/CustomGridToolbar";
import useAxios from "axios-hooks";
import SettingsService, {forms} from "../../services/settings.service";
import _, {each, isEmpty} from 'lodash'
import {AllInclusive, Archive, CheckCircle, Clear, PersonAdd, Refresh} from "@mui/icons-material";
import {API_URL, events} from "../../config";
import axios from "axios";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomPageHeader from "../../components/CustomPageHeader";
import CustomToggleGraph from "./CustomToggleGraph";
import CustomDashboardGraph from "../../components/charts/CustomDashboardGraph";
import {GlobalContext} from "../../state/global";
import ColumnsService from "../../services/columns.service";
import {customTheme} from "../../theme/customTheme";
import CustomTooltip from "../../components/CustomTooltip";
import AddUserDialog from "./AddUserDialog";
import ArchivedUsersDialog from "./ArchivedUsersDialog";
import AuthService from "../../services/auth.service";

const Iscrizioni = () => {
    let navigate = useNavigate()

    const [globalState,] = useContext(GlobalContext)
    const {settings, dataStructures} = globalState

    const [pageSize, setPageSize] = useState(10)
    const [dialogState, setDialogState] = useState({ open: false, generic: null })

    const _forms = useMemo(() => {
        if (!settings || !dataStructures)
            return null
        return SettingsService.getForms(settings, dataStructures)
    }, [settings, dataStructures])

    const [openArchivedDialog, setOpenArchivedDialog] = useState(false)

    const [activeForm, setActiveForm] = React.useState(null);
    const handleChangeActiveTab = (event, newValue) => {
        if(newValue === _forms.length)
            setOpenArchivedDialog(true)
        else
            setActiveForm(_forms[newValue]);
    };

    useEffect(() => {
        if (_forms) {
            setActiveForm(_forms[0])
        }
    }, [_forms])

    const [allUsersFlag, setAllUsersFlag] = useState(false)
    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({archived: false, all: allUsersFlag}), {method: "GET", useCache: false, manual: false}
    )

    const users = useMemo(() => {
        if (!allUsers || !_forms)
            return null
        else return _.orderBy(allUsers.filter(function (o) {
            return true //!o.roles || o.test === true// && !o.test
        }), 'completedAt._seconds', 'desc')
    }, [allUsers, _forms])

    const usersPerForm = useMemo(() => {
        if (!users)
            return {}

        const res = []

        each(_forms, (form) => {
            res[form.id] = _.orderBy(
                _.filter(users, function (o) {
                    return o.evento === form.id
                }),
                form.timelineField + '._seconds', 'desc'
            )
            if (form.id === forms.CHECKIN.id) {
                res[forms.CHECKIN.id] = _.orderBy(
                    _.filter(users, function (o) {
                        return (o.evento === events.NJ15.id || o.evento === events.BOTH.id || o.checkin === true)
                    }),
                    ['checkin', 'checkedInAt._seconds'],
                    ['asc', 'desc']
                )
            } else if (form.id === forms.CHECKIN_NR16.id) {
                res[forms.CHECKIN_NR16.id] = _.orderBy(
                    _.filter(users, function (o) {
                        return (o.evento === events.NR16.id || o.evento === events.BOTH.id || o.checkinNr16 === true)
                    }),
                    ['checkin', 'checkedInAt._seconds'],
                    ['asc', 'desc']
                )
            }
        })

        return res
    }, [users])

    const [filteredUsers, reports] = useMemo(() => {
        if (!usersPerForm || !activeForm)
            return [[], null]

        const _filteredUsers = usersPerForm[activeForm.id]

        let _reports = {}

        if (forms.CHECKIN.id === activeForm.id) {
            _reports = {
                'Partecipanti fisici entrati 15/03': _.filter(_filteredUsers, function (o) {
                    return o.checkin === true
                }).length,
                //'Partecipanti fisici': _.filter(_filteredUsers, ['partecipazione', 'In presenza']).length,
                //'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
            }
        } else if(forms.CHECKIN_NR16.id === activeForm.id) {
            _reports = {
                'Partecipanti fisici entrati 16/03': _.filter(_filteredUsers, function (o) {
                    return o.checkinNr16 === true
                }).length,
                //'Partecipanti fisici': _.filter(_filteredUsers, ['partecipazione', 'In presenza']).length,
                //'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
            }
        } else {
            _reports = {
                //[activeForm.id]: _filteredUsers?.length,
                'Utenti totali': _filteredUsers?.length,
                'Partecipanti fisici previsti': _.filter(_filteredUsers, function (o) {
                    return o.evento === 'nj15' ? o.partecipazione15 === 'si'
                        : o.evento === 'nr16' ? o.partecipazione16 === 'si'
                            : o.evento === 'Entrambi' ? (o.partecipazione15 === 'si' || o.partecipazione16 === 'si') : false
                }).length,
            }
        }

        //console.log("_filteredUsers:", _filteredUsers)

        return [_filteredUsers, _reports]
    }, [usersPerForm, activeForm])

    const [{data: config, loading: loadingConfig}, refetchConfig] = useAxios(ColumnsService.columnsUrl(), {
        method: "GET", useCache: false
    })

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    useEffect(() => {
        if (config)
            setColumnVisibilityModel(config.defaultVisibilityModel)
    }, [config])

    const checkinUser = async (id, nr16) => {
        //console.log("id:", id)
        await axios.post(API_URL + "participantsFisici/" + id + "/checkin"+"?nr16="+(nr16||''))
            .then(() => fetchUsers())
            .catch(err => {
                console.log("err:", err)
                setError(err.response?.data?.error || "Errore")
            })
    };

    const resetCheckInUser = async (id, nr16) => {
        //console.log("id:", id)
        await axios.post(API_URL + "participantsFisici/" + id + "/reset"+"?nr16="+(nr16||''))
            .then(() => fetchUsers())
            .catch(err => setError(err.response))
    };

    const columns = useMemo(() => {
        if(!globalState.dataStructures)
            return []

        const nr16 = activeForm?.id === forms.CHECKIN_NR16.id

        if (activeForm?.id === forms.CHECKIN_NR16.id) {
            const checkInCols = [{
                field: "action",
                headerName: "Action",
                sortable: false,
                flex: 1,
                renderCell: (params) => {
                    const onClick = async (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        await checkinUser(params.id, nr16)
                    };
                    const onClickReset = async (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        await resetCheckInUser(params.id, nr16)
                    };

                    const checkinField = nr16 ? 'checkinNr16' : 'checkin'
                    return !params.row[checkinField] ?
                        <Button variant={"contained"}
                                endIcon={<CheckCircle sx={{fontSize: '2.5rem'}}/>}
                                onClick={onClick}>Check-in</Button> :
                        <Button variant={"outlined"}
                                endIcon={<Clear sx={{color: 'red', fontSize: '2.5rem'}}/>}
                                onClick={onClickReset}>Reset Check-in</Button>;
                }
            }]

            return ColumnsService
                .getColumns(globalState.dataStructures[activeForm?.id])
                .concat(checkInCols)
        }

        return ColumnsService.getColumns(globalState.dataStructures[activeForm?.id])
    }, [globalState.dataStructures, activeForm])

    const handleSaveConfiguration = (newModel) => {
        setTimeout(function () {
            ColumnsService.saveDefaultVisibilityModel(newModel)
                .catch((err) => {
                    console.log("err:", err)
                    //setMessage({show: true, loading: false, text: "Configurazione non salvata", severity: "error"})
                })
                .finally(() => setColumnVisibilityModel(newModel))
        }, 200);
    }

    const [error, setError] = useState(null)

    const handleCloseError = () => {
        setError(null)
    }

    const [graphMode, setGraphMode] = useState(false);

    const handleGraphMode = (event, newMode) => {
        if (newMode !== null)
            setGraphMode(newMode);
    };

    const handleAddParticipant = (event, type) => {
        if(type === 'generic') {
            setDialogState({...dialogState, open: true, generic: true})
        } else {
            setDialogState({...dialogState, open: true, generic: false})
        }
    }

    const handleCloseDialog = (res) => {
        if(res?.status === 'success') {
            fetchUsers().then(() => {
                if(res?.userId)
                    navigate('/iscrizioni/user/'+res?.userId)
            })
        }
        setDialogState({...dialogState, open: false})
    }

    return (
        <div>
            <CustomBackdrop open={loadingUsers} children={false}/>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error"
                       sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
            <Box>
                <Box mb={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <CustomPageHeader title={'Elenco Iscrizioni'}
                                          refresh={fetchUsers}/>
                        {<CustomTooltip title={"Mostra tutti gli utenti caricati"}
                                           children={
                                               <Stack alignItems={'center'}>
                                                   <Switch
                                                       size={'small'}
                                                       checked={allUsersFlag}
                                                       onChange={() => setAllUsersFlag(!allUsersFlag)}
                                                       inputProps={{'aria-label': 'controlled'}}
                                                   />
                                                   <AllInclusive fontSize={'small'}/>
                                               </Stack>}
                            />}
                        <CustomTooltip title={'Aggiungi nuovo partecipante'}
                                       children={
                                           <Button variant={'outlined'} color={'accent'}
                                                   size={'small'}
                                                   sx={{ml: 1}}
                                                   startIcon={<PersonAdd/>}
                                                   onClick={(event) => handleAddParticipant(event, 'generic')}>
                                               Aggiungi
                                           </Button>
                                       }
                        />
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <CustomToggleGraph graphMode={graphMode} handleGraphMode={handleGraphMode}/>
                    </Box>
                </Box>
                <div style={{display: 'flex', height: 670,}}>
                    <div style={{flexGrow: 1}}>
                        {_forms &&
                            activeForm &&
                            usersPerForm &&
                            <Tabs variant={'standard'} value={activeForm?.position} onChange={handleChangeActiveTab}
                                  aria-label="forms tabs"
                                  sx={{overflow: "visible", "& .MuiTabs-scroller": {overflow: "visible !important"}}}>
                                {
                                    _forms && _forms?.map((tab) => {
                                        const checkinField = tab.id === forms.CHECKIN_NR16.id ? 'checkinNr16' : 'checkin'
                                        if (tab.id === forms.CHECKIN.id || tab.id === forms.CHECKIN_NR16.id)
                                            return <Tab key={tab.id}
                                                        sx={{
                                                            background: tab.id === activeForm?.id ?
                                                                alpha(customTheme.palette.primary.main, 0.35)
                                                                : alpha(customTheme.palette.primary.main, 0.1),
                                                            borderRadius: '8px 8px 0 0',
                                                            marginLeft: tab.id === forms.CHECKIN.id ? 'auto' : '8px',
                                                        }}
                                                        label={<Stack direction={'row'} alignItems={'center'}>
                                                            {`${tab.label}`}
                                                            <Chip
                                                                label={`${_.filter(usersPerForm[tab.id], [checkinField, true])?.length} / ${usersPerForm[tab.id]?.length}`}
                                                                size={'small'}
                                                                color={'primary'}
                                                                sx={{marginLeft: 1,}}
                                                            />
                                                        </Stack>}/>
                                        return <Tab key={tab.id}
                                                    sx={{
                                                        background: tab.id === activeForm?.id ?
                                                            alpha(customTheme.palette.accent.main, 0.3)
                                                            : alpha(customTheme.palette.accent.main, 0.1),
                                                        borderRadius: '8px 8px 0 0',
                                                        marginRight: 1
                                                    }}
                                                    label={<Stack direction={'row'} alignItems={'center'}>
                                                        {`${tab.label}`}
                                                        <Chip label={usersPerForm[tab.id]?.length}
                                                              size={'small'}
                                                              sx={{
                                                                  marginLeft: 1,
                                                                  background: customTheme.palette.accent.main,
                                                                  color: 'white'
                                                              }}
                                                        />
                                                    </Stack>}
                                        />
                                    })
                                }
                                <Tab sx={{
                                    borderRadius: '8px 8px 0 0',
                                    marginLeft: globalState.settings?.checkin ? 'inherit' : 'auto',
                                }}
                                     label={<Archive color={'primary'}/>}
                                />
                                <ArchivedUsersDialog openArchivedDialog={openArchivedDialog}
                                                     setOpenArchivedDialog={setOpenArchivedDialog}
                                                     refetch={fetchUsers}
                                />
                            </Tabs>
                        }
                        {
                            reports && !isEmpty(reports) &&
                            <Grow in={reports && !isEmpty(reports)}>
                                <Stack p={0.5} direction={'row'} spacing={0.5}
                                       sx={{background: alpha(customTheme.palette.accent.main, 0.1)}}>
                                    {
                                        Object.entries(reports || []).map(([key, value], i) => {
                                            return <Chip key={i} size={'small'} //color={'primary'} variant={'accent-outlined'}
                                                         label={`${key}: ${value || 0}`}/>
                                        })
                                    }
                                </Stack>
                            </Grow>
                        }
                        {!graphMode ?
                        <DataGrid
                            localeText={{
                                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                                toolbarExport: "Esporta colonne visibili"
                            }}
                            loading={loadingUsers || loadingConfig}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                handleSaveConfiguration(newModel)
                            }
                            disableSelectionOnClick
                            onRowClick={(params, event, details) => {
                                navigate("/iscrizioni/user/" + params.row.id, {state: {activeFormId: activeForm?.id}});
                                // TODO: Show row details
                            }}
                            components={{
                                Toolbar: () => {
                                    return <CustomGridToolbar buttons={[
                                        <CustomTooltip title={'Aggiungi nuovo partecipante'}
                                                       children={
                                                           <Button variant={'outlined'} color={'accent'}
                                                                   size={'small'}
                                                                   sx={{ml: 1}}
                                                                   startIcon={<PersonAdd/>}
                                                                   onClick={handleAddParticipant}>
                                                               Aggiungi
                                                           </Button>
                                                       }
                                        />
                                    ]}/>
                                },
                                LoadingOverlay: LinearProgress,
                            }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={!loadingConfig ? (columns || []) : []}
                            rows={filteredUsers || []}
                            //checkboxSelection={true}
                        />
                        : <CustomDashboardGraph
                            utenti={filteredUsers}
                            activeForm={activeForm}/>
                    }
                    </div>
                </div>
                <Fab onClick={fetchUsers}
                     color={'primary'}
                     size={"medium"}
                     variant={'action'}>
                    <Refresh/>
                </Fab>
            </Box>
            <AddUserDialog dialogState={dialogState} handleClose={handleCloseDialog} formId={activeForm?.id}/>
        </div>
    )
}

export default Iscrizioni
