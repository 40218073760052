import React from "react";
import _ from "lodash";
import {fieldTypes} from "../services/utils";
import {
    Box,
    Button,
    Checkbox, Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slide, Stack,
    TextField,
    Typography
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider, MobileDateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {ContentCopy, CopyAll, Link, VideoCameraFront} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import AuthService from "../services/auth.service";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "../services/auth-header";
import CustomTooltip from "./CustomTooltip";
import {customTheme} from "../theme/customTheme";

const CustomField = ({userId, formik, field, disabled}) => {
    const fieldType = _.find(fieldTypes, ['type', field.type])

    function getOptions() {
        switch (field.id) {
            case "luogoArrivo":
                return formik.values['mezzoDiTrasporto'] === "In aereo" ? ["Linate", "Malpensa"]
                    : formik.values['mezzoDiTrasporto'] === "In treno" ? ["Milano Centrale", "Milano Garibaldi"]
                        : (field?.values || [])
            default:
                return field?.values || []

        }
    }

    function getValue() {
        return field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]
    }

    function getOtherValue() {
        const val = getValue()
        switch (field.id) {
            case "luogoArrivo":
                return getOptions().includes(val) ? "" : val
            case "luogoPartenzaRitorno":
                return field.values.includes(val) ? "" : val
            default:
                return val

        }
    }

    //console.log("formik:",formik)

    return (
        <Slide in={true} exit={true}
               children={<Grid item xs={12} md={field?.md || fieldType?.md || 6}>
                   {(() => {
                       switch (fieldType) {
                           case fieldTypes.RADIO_GROUP:
                               return <FormControl fullWidth disabled={field.precompiled || disabled}>
                                   <Typography variant={'body2'}>{field.label}</Typography>
                                   <RadioGroup
                                       row
                                       value={field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                   >
                                       {
                                           (field.values || getOptions()).map((radio) =>
                                               <FormControlLabel key={radio} value={radio} control={
                                                   <Radio required/>} label={radio}/>
                                           )
                                       }
                                       {field.other
                                           && (field.condition && formik.values[field.condition.id] === field.condition.value)
                                           && <FormControlLabel
                                               checked={!getOptions().includes(getValue())}
                                               control={<Radio/>} label={<TextField
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               value={getOtherValue()}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                               variant={"standard"} placeholder={'Altro:'}/>
                                           }/>}
                                   </RadioGroup>
                               </FormControl>
                           case fieldTypes.CHECK_BOX:
                               return <FormControl>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={<Checkbox
                                               id={field.id}
                                               name={field.id}
                                               disabled={field.precompiled || disabled}
                                               checked={formik.values[field.id] === true
                                                   || formik.values[field.id] === 'si'}
                                               //onChange={formik.handleChange}
                                               //onBlur={formik.handleBlur}
                                               onChange={(event, checked) => {
                                                   formik.setFieldValue(field.id, checked ? 'si' : 'no')
                                                   formik.setFieldTouched(field.id)
                                               }}
                                           />} label={field.label}/>
                                   </FormGroup>
                               </FormControl>
                           case fieldTypes.SELECT:
                               return <FormControl variant="standard" fullWidth>
                                   <FormHelperText>{field.label}</FormHelperText>
                                   <Select
                                       disabled={field.precompiled || disabled}
                                       label={field.label}
                                       id={field.id}
                                       name={field.id}
                                       //value={dati[field.id]}
                                       value={formik.values[field.id]}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       //error={Boolean(formik.errors[field.id])}
                                       //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                       helperText={field.label}
                                   >
                                       {(field?.values || getOptions()).map(v =>
                                           <MenuItem value={v} key={v}>{v}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           case fieldTypes.DATE:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDatePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                           formik.setFieldValue(field.id, date)
                                           formik.setFieldTouched(field.id)
                                       }}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]?._seconds ?
                                           formik.values[field.id]._seconds * 1000
                                           : formik.values[field.id]
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy"/>
                               </LocalizationProvider>
                           case fieldTypes.DATE_TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDateTimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           formik.setFieldValue(field.id, date)
                                           formik.setFieldTouched(field.id)
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]?._seconds ?
                                           formik.values[field.id]._seconds * 1000
                                           : formik.values[field.id]
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy, HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <TimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           formik.setFieldValue(field.id, date)
                                           formik.setFieldTouched(field.id)
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]) || ''}
                                       inputFormat="HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.URL:
                               return formik.values[field.id] ?
                                   <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                       <CustomTooltip
                                           title={'Apri link in un\'altra finestra'}
                                           children={<Button href={formik.values[field.id]}
                                                             target={'_blank'}
                                                             sx={{padding: 1.5, backgroundColor: 'transparent'}}
                                                             onClick={(event) => event.stopPropagation()}>
                                           </Button>}/>
                                       <CustomTooltip
                                           title={"Copia link"}
                                           children={<IconButton color={'primary'}
                                                                 onClick={() =>
                                                                     navigator.clipboard.writeText(field.getter
                                                                         ? formik.values[field.getter][field.id]
                                                                         : formik.values[field.id])
                                           }>
                                               <ContentCopy fontSize={'small'}/>
                                           </IconButton>}/>
                                   </Box> : ''
                           case fieldTypes.ACCESS_TYPE:
                               if(formik.values.invito === 'Presenza') {
                                   return <Stack alignItems={'center'}>
                                       {formik.values.qrCodeUrl ?
                                           <Stack p={1} borderRadius={'1rem'}
                                                  border={`1px dashed ${customTheme.palette.accent.main}`}>
                                               <img src={formik.values.qrCodeUrl}
                                                    style={{width: '150px'}}/>
                                               <Chip label={`Area: ${formik.values.area || 'Non assegnata'}`}/>
                                           </Stack>
                                           : <Button variant={'contained'}
                                                       onClick={async (event) => {
                                                           event.stopPropagation()
                                                           await axios.get(API_URL + 'generate-qr-code/' + userId)
                                                               .then(() => window.location.reload())
                                                       }}>
                                                   Genera QR code per questo utente
                                               </Button>
                                       }
                                   </Stack>
                               } else if(formik.values.invito === 'Online') {
                                   return <Stack direction={"row"} spacing={1} justifyContent={'center'}>
                                       <CustomTooltip title={"Apri link streaming"} children={
                                           <Button href={formik.values.urlStreaming
                                               || `https://newjourney.it/${userId}`}
                                                   target={'_blank'}
                                                   color={'accent'}
                                                   variant={'outlined'}
                                                   endIcon={<VideoCameraFront/>}
                                           >
                                               Link allo streaming
                                           </Button>
                                       }/>
                                       <CustomTooltip title={"Copia link streaming"} children={
                                           <IconButton
                                               color={'accent'}
                                               variant={'outlined'}
                                               onClick={async (event) => {
                                                   event.stopPropagation()
                                                   await navigator.clipboard.writeText(
                                                       `https://newjourney.it/${userId}`)
                                               }}>
                                               <ContentCopy/>
                                           </IconButton>
                                       }/>
                                   </Stack>
                               }
                               return null

                           case fieldTypes.TEXT_BOX:
                               return <TextField id={field.id}
                                                 name={field.id}
                                                 disabled={field.precompiled || disabled}
                                                 multiline={true}
                                                 minRows={2}
                                                 maxRows={3}
                                                 value={formik.values[field.id]}
                                                 onChange={formik.handleChange}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"outlined"}
                                                 sx={{width: '100%'}}/>
                           default:
                               return <TextField id={field.id}
                                                 name={field.id}
                                                 disabled={field.precompiled || disabled}
                                                 value={formik.values[field.id]}
                                                 onChange={formik.handleChange}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>
                       }
                   })()}
               </Grid>}
        />)
}

export default CustomField