import React, {useState} from "react";
import UserService from "../../services/user.service";
import IconButton from "@mui/material/IconButton";
import {Archive, MarkEmailUnread, MoreVert, Save, Send, Unarchive} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import CustomTooltip from "../../components/CustomTooltip";
import axios from "axios";

const UserDetailsMenu = ({archive = true, userId, updateUser, onSuccess, onError}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, type: null});

    const handleToggleConfirmDialog = (event, type) => {
        setOpenConfirmDialog({
            open: !openConfirmDialog.open,
            type: type
        })
        handleCloseUserMenu()
    }

    const handleConfirm = async () => {
        if(openConfirmDialog.type === 'archive')
            await handleArchiveUser()
        else await handleSendEmail()
    }

    const handleArchiveUser = () => {
        updateUser({
            url: UserService.archiveUserUrl(userId, archive),
            method: "POST"
        })
            .then(() => {
                handleToggleConfirmDialog()
                onSuccess()
            })
            .catch((err) => {
                onError()
            })
    }

    const handleSendEmail = () => {
        axios.get(UserService.resendEmailToUserUrl(userId),
            {
                method: "GET"
            })
            .then(() => {
                handleToggleConfirmDialog()
                onSuccess()
            })
            .catch((err) => {
                onError()
            })
    }

    return (
        <>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenUserMenu}>
                <MoreVert/>
            </IconButton>
            <Menu id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseUserMenu}
                  MenuListProps={{
                      'aria-labelledby': 'basic-button',
                  }}>
                <MenuItem onClick={(event) => handleToggleConfirmDialog(event, 'archive')}>
                    <ListItemIcon>
                        {
                            archive ?
                                <Archive fontSize="small" color={'accent'}/>
                                : <Unarchive fontSize="small" color={'accent'}/>
                        }
                    </ListItemIcon>
                    <ListItemText>{archive ? 'Archivia' : 'Ripristina'}</ListItemText>
                </MenuItem>
                <MenuItem onClick={(event) => handleToggleConfirmDialog(event, 'sendEmail')}>
                    <ListItemIcon>
                        {
                            <MarkEmailUnread fontSize="small" color={'accent'}/>
                        }
                    </ListItemIcon>
                    <ListItemText>{'Invia e-mail AGENDA'}</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog open={openConfirmDialog.open} onClose={handleToggleConfirmDialog}>
                <DialogContent>
                    {openConfirmDialog.type === 'archive' ?
                        <DialogContentText>
                            Sei sicuro di voler {archive ? 'archiviare' : 'ripristinare'} l'utente?
                        </DialogContentText>
                        : openConfirmDialog.type === 'sendEmail' ?
                            <DialogContentText>
                                Sei sicuro di voler inviare l'email all'utente?
                            </DialogContentText>
                            : null
                    }
                </DialogContent>
                {openConfirmDialog.type === 'archive' ?
                    <DialogActions>
                        <Button onClick={handleToggleConfirmDialog}>Annulla</Button>
                        <Button onClick={handleConfirm} variant={'submit'}
                                startIcon={
                                    archive ?
                                        <Archive/>
                                        : <Unarchive/>}>
                            {archive ? 'Archivia' : 'Ripristina'}</Button>
                    </DialogActions>
                    : openConfirmDialog.type === 'sendEmail' ?
                        <DialogActions>
                            <Button onClick={handleToggleConfirmDialog}>Annulla</Button>
                            <Button onClick={handleConfirm} variant={'submit'}
                                    disabled={true}
                                    startIcon={<Send/>}>
                                {'Invia AGENDA'}
                            </Button>
                        </DialogActions>
                        : null
                }
            </Dialog>
        </>
    );
}

export default UserDetailsMenu