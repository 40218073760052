import {Check, Close, ContentCopy, Link} from "@mui/icons-material";
import React from "react";
import _, {map} from 'lodash'
import {fieldTypes} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";
import {Box, Button, Chip, Stack, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import QrCodeComponent from "../components/QrCodeComponent";

const columnsUrl = () => {
    return `${API_URL}config/columns`
}

const saveColumsToExport = (columns) => {
    return axios.post(columnsUrl(), {defaultExportColumns: columns}, {headers: authHeader()})
};

const saveDefaultVisibilityModel = (columns) => {
    return axios.post(columnsUrl(), {defaultVisibilityModel: columns}, {headers: authHeader()})
};

const getFieldsFromForm = (form) => {
    let fields = []
    if(form) {
        if (form.sections) {
            form.sections.forEach((section) => {
                fields = fields.concat(map(section.fields, (field) => ({...field, section: section.label})))
            })
            return fields
        } else {
            return form.fields
        }
    }
}

const getColumns = (form) => {
    const cols = getFieldsFromForm(form)?.map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.label || field.id || (_.startCase(field)),
            flex: 1,
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }}

        const formType = _.find(fieldTypes, ['type', field.type])
        switch (formType) {
            case fieldTypes.DATE:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleDateString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.DATE_TIME:
                return {
                    ...baseField,
                    type: 'date',
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.TIME:
                return {
                    ...baseField,
                    type: 'date', // TODO: capire
                    valueFormatter: (params) => {
                        return (params.value?._seconds ?
                            new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                            : params.value ?
                                new Date(params.value).toLocaleTimeString('it-IT')
                                : "");
                    },
                }
            case fieldTypes.CHECK_BOX:
                return {
                    ...baseField,
                    //flex: 0.6,
                    valueFormatter: (params) => {
                        if (params.value === true || params.value === 'si') {
                            return 'Sì';
                        } else if (params.value === false || params.value === 'no')
                            return 'No';
                        else return 'Non specificato'
                    },
                    renderCell: (params) => (params.value === true || params.value === 'si' || params.value === '1')
                        ? <Check sx={{color: 'green'}}/>
                        : (params.value === false || params.value === '' || params.value === 'no')
                            ? <Close color={'disabled'}/>
                            : '-'
                }
            case fieldTypes.URL:
                return {
                    ...baseField,
                    renderCell: (params) => params.value ?
                             <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Tooltip title={"Copia link al qr"}>
                                <IconButton size={'small'} color={'primary'}
                                            onClick={() => navigator.clipboard.writeText(params.value)
                                                //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))
                                            }>
                                    <ContentCopy fontSize={'small'}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Apri link un\'altra finestra'}>
                                <Button href={params.value}
                                        target={'_blank'}
                                        sx={{backgroundColor: 'transparent'}}
                                        size={'small'}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                        }}>
                                </Button>
                            </Tooltip>
                        </Box> : ""
                }
            case fieldTypes.ACCESS_TYPE:
                return {
                    ...baseField,
                    renderCell: (params) => {
                        if(params.row.invito === 'Online')
                            return <CustomTooltip title={"Copia link streaming"} children={
                                <Button
                                    color={'accent'}
                                    onClick={async (event) => {
                                        event.stopPropagation()
                                        await navigator.clipboard.writeText(
                                            `https://newjourney.it/${params.id}`)
                                    }}>
                                    <Link/>
                                </Button>
                            }/>
                        else if(params.row.invito === 'Presenza')
                            return !!params.row.qrCodeUrl ?
                                <QrCodeComponent qrCodeUrl={params.row.qrCodeUrl}/>
                                : <Chip label={'No QR Code'} size={'small'}/>
                    }
                }
            case fieldTypes.RADIO_GROUP:
                return {
                    ...baseField,
                    //flex: 0.6,
                    valueFormatter: (params) => {
                        if (params.value === true || params.value === 'si') {
                            return 'Sì';
                        } else if (params.value === false || params.value === 'no')
                            return 'No';
                        else return params.value
                    },
                    renderCell: (params) => (params.value === true || params.value === 'si' || params.value === '1')
                        ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                        : (params.value === false || params.value === 'no')
                            ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                            : (params.value || '-')
                }
            default:
                return baseField
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;


export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]