import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {ConnectedTv, CoPresent, Help, Save} from "@mui/icons-material";
import {find, some} from "lodash";
import axios from "axios";
import UserService from "../../services/user.service";
import CustomTooltip from "../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import {events} from "../../config";

const AddUserDialog = ({dialogState, handleClose, formId}) => {
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [sendEmail, setSendEmail] = useState(false)
    const [userData, setUserData] = useState({
        email: '',
        nome: '',
        cognome: '',
        lingua: 'Ita',
        evento: dialogState.generic ? events.NJ15.id : formId,
        invito: 'Presenza'
    })

    useEffect(() => {
        if(!dialogState.generic)
            setUserData({
                ...userData,
                evento: dialogState.generic ? events.NJ15.id : formId
            })
    }, [dialogState, formId])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldId = event.target.id || event.target.name
        if(fieldId === 'email') {
            setIsValidEmail(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value))
        }

        setUserData({...userData, [fieldId]: event.target.value});
    };

    const handleSubmit = () => {
        axios({
            url: `${UserService.usersUrl({})}/${userData.evento}?sendEmail=${sendEmail}`,
            method: 'POST',
            data: userData,
        })
            .then((res) => {
                if(res) {
                    setUserData({
                        email: '',
                        nome: '',
                        cognome: '',
                        lingua: 'Ita',
                        evento: dialogState.generic ? events.NJ15.id : formId,
                        invito: 'Presenza'
                    })
                    handleClose({status: 'success', userId: res.data})
                }
            })
            .catch((err) => console.log("handleSubmit new user --> err:", err))
    }

    return (
        <Dialog open={dialogState.open} onClose={handleClose} maxWidth={'md'} fullWidth
                PaperProps={{variant: 'dialog'}} scroll={'paper'}>
            <DialogTitle>{(find(events, ['id', formId])?.label || '') + ': Aggiungi un nuovo partecipante'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    sx={{width: '50%', paddingRight: 4}}
                    margin="dense"
                    id="nome"
                    name="nome"
                    label="Nome"
                    type="text"
                    variant="standard"
                    required
                    value={userData.nome}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    sx={{width: '50%'}}
                    margin="dense"
                    id="cognome"
                    name="cognome"
                    label="Cognome"
                    type="text"
                    variant="standard"
                    required
                    value={userData.cognome}
                    onChange={handleChange}
                />
                <TextField
                    autoFocus
                    sx={{width: '50%', paddingRight: 4}}
                    margin="dense"
                    id="email"
                    name="email"
                    label="E-mail"
                    type="email"
                    fullWidth
                    variant="standard"
                    required
                    value={userData.email}
                    onChange={handleChange}
                />
                <FormControl fullWidth sx={{mt: 2}}>
                    <Typography variant={'body2'}>Lingua</Typography>
                    <RadioGroup
                        row
                        value={userData.lingua}
                        defaultValue={'Ita'}
                        onChange={handleChange}
                        name={'lingua'}
                        id={'lingua'}
                    >
                        <FormControlLabel value={'Ita'} control={
                            <Radio required/>} label={'Italiano'}/>
                        <FormControlLabel value={'Eng'} control={
                            <Radio required/>} label={'Inglese'}/>
                    </RadioGroup>
                </FormControl>
                <FormControl fullWidth sx={{mt: 2}}>
                    <Typography variant={'body2'}>Partecipazione evento</Typography>
                    <RadioGroup
                        row
                        value={userData.evento}
                        onChange={handleChange}
                        //defaultValue={dialogState.generic ? events.NJ15.id : formId}
                        name={'evento'}
                        id={'evento'}
                    >
                        {
                            Object.values(events).map((event, i) => (
                                <FormControlLabel key={i} value={event.id} control={
                                    <Radio required disabled={!dialogState.generic}/>} label={event.label}/>
                            ))
                        }
                    </RadioGroup>
                </FormControl>
                <Box width={'100%'}>
                    <FormControl sx={{mt: 2}}>
                        <Typography variant={'body2'}>Invitato in</Typography>
                        <RadioGroup
                            value={userData.invito}
                            onChange={handleChange}
                            defaultValue={'Presenza'}
                            name={'invito'}
                            id={'invito'}
                        >
                            <FormControlLabel value={'Presenza'} control={
                                <Radio required/>} label={<Stack direction={'row'} spacing={1}>
                                <CoPresent color={'primary'}/>
                                <ListItemText primary={'Presenza'}/>
                            </Stack>}/>
                            <FormControlLabel value={'Online'} control={
                                <Radio required/>} label={<Stack direction={'row'} spacing={1}>
                                <ConnectedTv color={'primary'}/>
                                <ListItemText primary={'Online'}/>
                            </Stack>}/>
                        </RadioGroup>
                    </FormControl>
                </Box>
                <FormControl sx={{mt: 2}}>
                    <FormGroup row sx={{alignItems: 'center'}}>
                        <FormControlLabel
                            control={<Checkbox
                                id={'invio-email'}
                                name={'invio-email'}
                                checked={sendEmail}
                                onChange={(event, checked) => {
                                    setSendEmail(checked)
                                }}
                            />} label={'Invia email di registrazione'}/>
                        <CustomTooltip title={"Verrà inviata un'email automatica per permettere la registrazione dell'utente inserito"}
                                       children={<IconButton size={'small'}><Help color={'disabled'} fontSize={'small'}/></IconButton>}/>
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button variant={'submit'} startIcon={<Save/>}
                        disabled={!isValidEmail || (some(Object.values(userData), function (o) {
                            return !o
                        }))}
                        onClick={handleSubmit}>
                    Aggiungi
                </Button>

            </DialogActions>
        </Dialog>
    );
}

export default AddUserDialog