import axios from "axios";
import {API_URL} from "../config";
import React from "react";
import jwtDecode from "jwt-decode";

const register = (mrMs, name, surname, email, country, company) => {
    return axios.post(API_URL + "register", {
        mrMs,
        name,
        surname,
        email,
        country,
        company
    });
};

const login = async (username, password) => {
    return axios
        .post(API_URL + "login", {
            username,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data
        });
};

const isLogged = (user) => {
    if(!user)
        return false
    const expiry = (JSON.parse(atob(user.split('.')[1]))).exp;
    return expiry * 1000 >= Date.now();

};

const getCurrentUserData = () => {
    const token = localStorage.getItem(`user`)
    try {
        return jwtDecode(token)
    } catch (e) {
        logout()
    }
    return null
};

const isCurrentUserAdmin = () => {
    const token = localStorage.getItem(`user`)
    try {
        const decodedData = jwtDecode(token)
        return decodedData.roles.includes('Plesh')
    } catch (e) {
        logout()
    }
    return null
};

const getToken = () => {
    return JSON.parse(localStorage.getItem("user"))?.token;
};

const logout = () => {
    localStorage.removeItem("user");
    window.location.reload();
};

const AuthService = {
    register,
    login,
    isLogged,
    getCurrentUserData,
    logout,
    getCurrentUserAdmin: isCurrentUserAdmin,
    getToken
};
export default AuthService;
